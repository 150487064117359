@import "variables", "mixins";

/*
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2016 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Mark Simonson Studio
 * License URL: https://www.fontspring.com/licenses/mark-simonson-studio/webfont
 *
 *
 */

@font-face {
    font-family: $font-family-proxima-nova;
    src: url('../../fonts/proximanova-regular.eot');
    src: url('../../fonts/proximanova-regular.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/proximanova-regular.woff2') format('woff2'),
         url('../../fonts/proximanova-regular.woff') format('woff'),
         url('../../fonts/proximanova-regular.ttf') format('truetype'),
         url('../../fonts/proximanova-regular.svg#proxima_novabold') format('svg');
    font-weight: $font-weight-normal;
    font-style: normal;
}

@font-face {
    font-family: $font-family-proxima-nova;
    src: url('../../fonts/proximanova-regular-italic.eot');
    src: url('../../fonts/proximanova-regular-italic.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/proximanova-regular-italic.woff2') format('woff2'),
         url('../../fonts/proximanova-regular-italic.woff') format('woff'),
         url('../../fonts/proximanova-regular-italic.ttf') format('truetype'),
         url('../../fonts/proximanova-regular-italic.svg#proxima_novabold') format('svg');
    font-weight: $font-weight-normal;
    font-style: italic;
}

@font-face {
    font-family: $font-family-proxima-nova;
    src: url('../../fonts/proximanova-semibold.ttf') format('truetype');
    font-weight: $font-weight-semibold;
    font-style: normal;
}

@font-face {
    font-family: $font-family-proxima-nova;
    src: url('../../fonts/proximanova-bold.eot');
    src: url('../../fonts/proximanova-bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/proximanova-bold.woff2') format('woff2'),
         url('../../fonts/proximanova-bold.woff') format('woff'),
         url('../../fonts/proximanova-bold.ttf') format('truetype'),
         url('../../fonts/proximanova-bold.svg#proxima_novabold') format('svg');
    font-weight: $font-weight-bold;
    font-style: normal;
}
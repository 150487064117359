@import "variables", "mixins";

//
// Headings
//

h1, .h1 { font-size: $font-size-h1-xs; }
h2, .h2 { font-size: $font-size-h2-xs; }
h3, .h3 { font-size: $font-size-h3-xs; }
h4, .h4 { font-size: $font-size-h4-xs; }
h5, .h5 { font-size: $font-size-h5-xs; }

@include media-breakpoint-up(md) {
    h1, .h1 { font-size: $font-size-h1-md; }
    h2, .h2 { font-size: $font-size-h2-md; }
    h3, .h3 { font-size: $font-size-h3-md; }
    h4, .h4 { font-size: $font-size-h4-md; }
    h5, .h5 { font-size: $font-size-h5-md; }
}

@include media-breakpoint-up(lg) {
    h1, .h1 { font-size: $font-size-h1-lg; }
    h2, .h2 { font-size: $font-size-h2-lg; }
    h3, .h3 { font-size: $font-size-h3-lg; }
    h4, .h4 { font-size: $font-size-h4-lg; }
    h5, .h5 { font-size: $font-size-h5-lg; }
}

h3, h4,
.h3, .h4 {
    font-weight: $font-weight-normal;
}


//
// Emphasis
//

small,
.small {
    font-size: $font-size-xs;
}


//
// Misc
//

// Blockquotes
.blockquote {
    padding: 0;
    color: $blockquote-color;
    font-style: $blockquote-font-style;
    font-size: $blockquote-font-size-xs;
    font-weight: lighter;
    line-height: 1.33;
    border-left: none;

    p {
        margin-bottom: 1rem;

        &:before {
            content: '\201C';
        }

        &:after {
            content: '\201D';
        }
    }

    @include media-breakpoint-up(sm) {
        p {
            margin-bottom: 1.5rem;
        }
    }

    @include media-breakpoint-up(md) {
        font-size: $blockquote-font-size-md;

        p {
            margin-bottom: 2rem;
        }
    }
}

.blockquote-footer {
    height: auto;
    font-size: 0.9375rem;
    font-style: normal;
    opacity: 0.6;

    &:before {
        content: '';
    }

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
        opacity: 0.8;
    }
}
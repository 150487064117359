@import "variables", "mixins";

header {
    height: $navbar-height-xs;

    @include media-breakpoint-up(sm) {
        @media (min-height: $min-device-height) {
            height: $navbar-height-sm;
        }
    }
}

.atSchoolname {
    text-align: center;
    padding-top: 1rem;
    font-size: 1rem;
}

.toplineSchoolname {
    font-size: 1.5rem;
    margin-bottom: 3rem;
    font-weight: 600;
    text-align: center;

    .toplineSchoollogo {
        display: inline-block;
        margin: 0 10px;
    }
}

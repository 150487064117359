@import "variables", "mixins";

.side-menu {
    position: fixed;
    top: 0;
    left: 0;
    padding: $block-spacing-xs $grid-gutter-width-base;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $side-menu-bg;
    color: $side-menu-color;
    overflow-y: auto;
    z-index: $zindex-fixed;
    transform: translateX(-100%);
    transition: transform .3s $ease-out-quart;
    overscroll-behavior: contain;

    &.active {
        transform: translateX(0%);
    }

    .logo-wrapper {
        margin-bottom: 30px;
    }

    .nav {
        flex-direction: column;
        margin-bottom: 50px;
        min-height: fit-content;
    }

    .nav-heading {
        margin-bottom: .375rem;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
        letter-spacing: .08em;
        text-transform: uppercase;
        color: $gray-light;
    }

    .nav-link {
        padding: .25em 0;
        color: #fff;
        font-size: $font-size-base;
        line-height: $font-size-base * 1.5;
        opacity: .75;
        transition: opacity .2s;
        white-space: nowrap;
        overflow: hidden;

        @include hover-focus {
            opacity: 1;
        }

        &.active {
            opacity: 1;
            font-weight: $font-weight-semibold;
        }

        &.menuhighlight {
            text-decoration: underline;
        }
    }

    .nav-link-icon {
        display: flex;
        align-items: center;

        span {
            margin-right: 15px;
        }

        img {
            margin-left: auto;
            margin-right: 0; // -2px;
            height: 18px;
        }
    }

    button.nav-link {
        background: transparent;
        border: none;
        text-align: left;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    [data-toggle=collapse] {
        display: flex;
        align-items: center;

        .icon-chevron-down {
            margin-left: auto;
            margin-right: 2px;
            transform: rotateX(180deg);
            transition: transform .3s;
        }

        &.collapsed {
            .icon-chevron-down {
                transform: rotateX(0deg);
            }
        }
    }

    .nav-start {
        margin-bottom: 20px;
    }

    .nav-end {
        margin-top: auto;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
        padding: $block-spacing-sm $grid-gutter-width-base;
        width: $side-menu-width;
        overscroll-behavior: auto;
    }

    @include media-breakpoint-up(md) {
        padding: $block-spacing-md $grid-gutter-width-base;
    }

    @include media-breakpoint-up(lg) {
        transform: none;
        transition: none;
    }

    @media print {
        display: none;
    }
}


// Button for toggling the menu when in its collapsed state
.side-menu-toggler {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: ($grid-gutter-width-xs - .5rem);
    padding: .5rem;
    border: none;
    background-color: transparent;
    line-height: 0;
    z-index: $zindex-fixed;

    &:focus {
        outline: none;
    }

    svg {
        width: 40px;
        height: 40px;
        stroke-width: 2px;
        stroke: $side-menu-bg;
        transition: stroke .3s;
    }

    path {
        transition: transform .3s ease-out, opacity .3s;
    }

    path:nth-child(1) { transform-origin: 50% 8px; }
    path:nth-child(3) { transform-origin: 50% 24px; }

    &.active {
        position: fixed;

        svg {
            stroke: $side-menu-color;
        }

        path:nth-child(1) { transform: translateY(8px) rotate(45deg); }
        path:nth-child(2) { opacity: 0; }
        path:nth-child(3) { transform: translateY(-8px) rotate(-45deg); }
    }

    @include media-breakpoint-up(sm) {
        &.active {
            svg {
                stroke: $side-menu-bg;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }

    @media print {
        display: none;
    }
}

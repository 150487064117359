@import "variables", "mixins";

//
// Basic Bootstrap table
//

.table {
    th {
        font-weight: $font-weight-semibold;
        white-space: nowrap;
    }

    .noborder {
        td {
            border-top: 0;
        }
    }
}


//
// Table with action menus
//

.table .dropdown {
    text-align: right;

    .btn {
        margin: -5px -10px;
        padding: 5px 10px;
        line-height: 0;
        font-size: 1rem;

        @include hover-focus {
            text-decoration: none;
        }
    }

    .dropdown-menu-right {
        margin-right: -4px;
    }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
    tbody tr {
        cursor: pointer;

        @include hover {
            background-color: $table-bg-hover;
        }
    }
}



// Responsive tables
//
// Add `.table-responsive` to tables and we'll make them mobile friendly by
// enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
    display: table;
    width: 800px;
    max-width: none;
    overflow-x: initial;

    @include media-breakpoint-up(md) {
        width: 100%;
        max-width: 100%;
    }

    @media print {
        width: 100%;
        max-width: 100%;
    }
}


.table-trailing-button {
    text-align: center;
}



// Sortable tables
//
// Add `.table-sortable` to tables and a `.sort-handle` to each row
// to enable drag & drop sorting.

.table-sortable {
    .sort-handle {
        cursor: move;
    }

    .sortable-ghost {
        opacity: 0;
    }
}

.table-sortable.dragging {
    tr:hover {
        background-color: transparent;
    }
}

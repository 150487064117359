@import "variables", "mixins";

// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.nav {

}

.nav-link {

    .go-back {
        display: inline-block;
        
        margin-right: .5rem;
        width: .75rem;
    
        line-height: 0;
        stroke: $blue;
        fill: $blue;
    }
    
}

.svg {
    display: inline-block;
    
    margin: 0 .5rem;
    width: .75rem;

    line-height: 0;
    stroke: $blue;
    fill: $blue;
}


// Nav inline

.nav-inline {
    .nav-item + .nav-item,
    .nav-link + .nav-link {
        margin-left: 2rem;
    }
}


// Nav footer

.nav-footer {
    flex-direction: column;
    border-top: solid 1px fade-out(#cacaca, 0.7);
    text-align: center;

    .nav-item {
        display: block;
        border-bottom: solid 1px rgba(202, 202, 202, 0.3);

        + .nav-item {
            margin-left: 0;
        }
    }

    .nav-link {
        display: block;
        padding: 0;
        font-size: $font-size-sm;
        line-height: $navbar-height-xs;
        color: #b0b0b0;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-around;

        .nav-item {
            display: inline-block;
            border-bottom: none;
        }

        .nav-link {
            display: inline-block;
            font-size: $font-size-xs;
            line-height: $navbar-height-sm;
        }
    }

    @include media-breakpoint-up(md) {
        justify-content: center;

        .nav-item + .nav-item {
            margin-left: 3.5rem;
        }
    }
}


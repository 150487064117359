@import "variables", "mixins";

footer {


    @media print {
        display: none;
    }

    @include media-breakpoint-up(sm) {
        height: $navbar-height-sm;
    }
}

.pagebreak {
    page-break-after: always;
}

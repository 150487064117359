@import "variables", "mixins";

.calendar {
    .row-days {
        margin-bottom: .5rem;
    }

    .col-day {
        padding-bottom: 1rem;
        text-align: center;
        border-bottom: solid 1px $gray-lighter;
    }

    h1 {
        margin-bottom: -.25rem;

        &.today {
            color: $green;
        }

        &.condensed {
            font-size: $font-size-h1-xs;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: -.5rem;
        }
    }

    .subheading {
        margin-bottom: 0;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
        color: $gray-light;
        opacity: .6;

        &.condensed {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            font-size: $font-size-sm;
        }

    }

    .row-things {
        position: relative;
        margin-bottom: .375rem;

        &.condensed {
            border-bottom: 1px solid #eceeef;
        }
    }

    .col-things {
        border: dashed 2px transparent;
        // padding: 0 .375rem .375rem 0;
        overflow: hidden;
        // border-top: solid 1px $gray-lighter;
    }

    .col-things + .col-things {
        margin-left: .375rem;
    }

    .period-vertical {
        position: absolute;
        top: 50%;
        left: -.5rem;
        transform-origin: top left;
        transform: rotate(-90deg) translate(-50%, -100%);
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
        opacity: .4;
        letter-spacing: .05rem;
    }


    // .period {
    //     // display: flex;
    //     // align-items: center;
    //     margin: auto;
    //     margin-top: 1.75rem;
    //     // margin-bottom: .75rem;
    //     // margin-left: .75rem;
    //     margin-bottom: 0;

    //     .label {
    //         text-transform: uppercase;
    //         font-size: $font-size-xs;
    //         font-weight: $font-weight-semibold;
    //         letter-spacing: .05rem;
    //         color: #666;
    //     }

    //     &.btn {
    //         // flex: 0 0 1rem;
    //         // margin-left: auto;
    //         // margin: auto;
    //         // opacity: 0;
    //         opacity: 0.4;
    //         font-size: .75rem;
    //         text-transform: uppercase;
    //     }
    // }

    .btn-period {
        position: relative;
        min-width: 120px;
        width: 100%;
        overflow: hidden;
        font-size: .75rem;
        text-align: center;
        text-transform: uppercase;
        background-color: #fff;
        transition: background-color .2s;
        padding-left: 0;
        padding-right: 0;

        .label-period {
            color: $blue;
            transition: opacity .2s;
        }

        .non-period {
            color: $black;
            transition: opacity .2s;
            opacity: .4;
        }

        &.condensed {
            padding: 0;
        }

        .label-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .2s;
        }

        &:hover {
            background-color: $blue;

            .label-period {
                opacity: 0;
            }

            .label-btn {
                opacity: 1;
            }
        }
    }

    // .col-things:hover .period .btn {
    //     opacity: 1;

    // }

    .item {
        position: relative;
        display: block;
        margin: .375rem auto;
        padding: .5rem .75rem;
        width: 100%;
        background-color: $gray-lightest;
        border: none;
        border-radius: $border-radius;
        text-align: left;
        cursor: pointer;
        transition: background-color .2s;

        &.myitem {
            border-left: solid $border-radius $brand-primary;
        }
        &.pending {
            border-left: dashed $border-radius $brand-primary;
        }
        &.approval-required {
            border-left: dashed $border-radius red;
        }

        &:hover {
            background-color: $gray-lighter;
        }

        &:focus {
            outline: none;
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: .5;
            background-color: $gray-lightest;
        }

        &.condensed {
            margin: 0;
            padding: 0 .25rem;
            height: 1.25rem;
            overflow: hidden;
        }

        p.add {
            opacity: .2;
        }

        p {
            // display: inline-block;
            margin-bottom: 0;
        }

        .description {
            font-size: $font-size-sm;
        }

        .teacher {
            display: inline-block;
            margin-left: .4rem;
            font-weight: $font-weight-semibold;
            font-size: .75rem;
            letter-spacing: .025rem;
            opacity: .4;
        }

        .pool-count {
            display: block;
            font-size: 11px;
            font-weight: $font-weight-semibold;
            opacity: .7;
        }
    
        .icons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap-reverse;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            padding: .25rem;
        }
    
        .icon { 
            margin: .25rem;
            height: 6px;
            width: 6px;
            border-radius: 3px;
            opacity: 0.3;
        }

        .icon-locked {
            background-color: $pink;
        }

        .icon-recurring {
            background-color: $blue;
        }

        .icon-attachment {
            background-color: $green;
        }

        .icon-pending {
            background-color: $pink;
        }
    }
}

.calendar-booking-detail {
    z-index: $zindex-dropdown;
    display: none;
    // margin: 6px 0;
    width: 300px;
    background-color: $gray-dark;
    color: $white;
    border-radius: $border-radius;
    box-shadow: 0 4px 10px rgba(#000, .2);

    &.show {
        display: block;
    }

    &.tether-element-attached-top {
        margin-top: 6px;
    }

    &.tether-element-attached-bottom {
        margin-top: -6px;
    }

    // .element {    
    //     &.tether-target-attached-top {
    //         &.tether-element-attached-bottom {
    //             margin-top: -@element-offset;
    //         }
    //     }
    
    //     &.tether-target-attached-bottom {
    //         &.tether-element-attached-top {
    //             margin-top: @element-offset;
    //         }
    //     }
    // }

    .content {
        display: flex;
        padding: 12px;

        &.optionals {
            padding-top: 0;
            padding-bottom: 0;
            max-height: 4rem;
            overflow-y: scroll;
        }
    }

    .details {
        flex: 1 1 auto;
    }

    .description {
        margin: 0;
        font-size: $font-size-sm;
    }

    .user {
        margin: 0;
        font-size: $font-size-base;
        font-weight: $font-weight-semibold;
    }

    .icons {
        margin-top: 4px;
        font-size: 0;

        svg {
            margin-right: 4px;
            width: 15px;
            height: 15px;
            fill: currentColor;
            opacity: .7;
        }
    }

    .meta {
        flex: 0 0 auto;
        margin-top: 3px;
        margin-left: 10px;

        p {
            margin: 0;
            font-size: .75rem;
            text-align: right;
            white-space: nowrap;
            color: rgba($white, .7);
        }

        p + p {
            margin-top: 2px;
        }
    }

    hr {
        margin: 0 12px;
        border: none;
        border-top: solid 1px rgba(#fff, .2);
    }

    .actions {
        margin: 8px 0;
    }

    .action-item {
        display: block;
        width: 100%;
        padding: 2px 12px;
        text-align: left;
        font-size: $font-size-xs;
        font-weight: $font-weight-normal;
        white-space: nowrap;
        color: $dropdown-link-color;
        background: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;

        &:hover {
            color: $dropdown-link-hover-color;
            background: $dropdown-link-hover-bg;
        }

        &:active {
            color: $dropdown-link-active-color;
            background: $dropdown-link-active-bg;
        }
    }
}

.resource-notes {
    margin: 0;
    padding: 0;
}


.dateSelector {
    padding-top: 0;
    padding-bottom: 0;
    width: 160px;
    height: 1.6rem;
    display: inline-block;

    &:focus {
        border-color: rgb(216, 216, 216);
    }
}

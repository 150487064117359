@import "variables", "mixins";

html {
	overflow-x: hidden;

	// reduce base font size when printing
	@media print {
		font-size: 16px;
	}
}

body {
	overflow: hidden;
}

body.has-side-menu {
	@include media-breakpoint-up(lg) {
		padding-left: $side-menu-width;
	}

	@media print {
		padding-left: 0;
	}
}

main {
	min-height: calc(100vh - 306px);

	@include media-breakpoint-up(sm) {
        min-height: calc(100vh - #{$navbar-height-sm});
	}
	
	@media print {
		min-height: 0;
	}
}

header + main {
	@include media-breakpoint-up(sm) {
		min-height: calc(100vh - #{$navbar-height-sm * 2});
	}

	@media print {
		min-height: 0;
	}
}

.side-menu-toggler + main {
	margin-top: (5rem - $block-spacing-xs);
	min-height: calc(100vh - 306px - #{5rem - $block-spacing-xs});

	@include media-breakpoint-up(sm) {
		margin-top: (5rem - $block-spacing-sm);
		min-height: calc(100vh - #{$navbar-height-sm} - #{5rem - $block-spacing-sm});
	}

	@include media-breakpoint-up(md) {
		margin-top: (5rem - $block-spacing-md);
		min-height: calc(100vh - #{$navbar-height-sm} - #{5rem - $block-spacing-md});
	}

	@media print {
		min-height: 0;
	}
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
}


.image-icon {
	width: 100%;
	max-width: 90px;
}
.image-large {
	width: 100%;
	max-width: 700px;
}



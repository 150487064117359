@import "variables", "mixins";

.buttons-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn + .btn {
        margin-top: 6px;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: center;

        .btn + .btn {
            margin-top: 0;
            margin-left: 6px;
        }
    }
}
@import "variables", "mixins";

// Progress bar with steps

$progress-bar-width: 4px;

.progress-steps {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 1.25rem;

    .progress {
        position: absolute;
        top: .5rem;
        left: .625rem;
        right: .625rem;
    }

    .progress-bar {
        height: $progress-bar-width;
    }

    .step {
        position: relative;
        height: 1.25rem;
        width: 1.25rem;
        border: solid $progress-bar-width $progress-bar-bg;
        border-radius: 50%;
        background-color: $white;
    }

    .step-heading {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: $progress-bar-width;
        margin-bottom: 0;
        transform: translateX(-50%);
        color: $progress-bg;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        white-space: nowrap;
    }

    .step-subheading {
        display: none;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: 1.5rem;
        margin-bottom: 0;
        transform: translateX(-50%);
        color: $progress-bg;
        font-size: $font-size-xs;
        white-space: nowrap;
    }

    .step.active {
        .step-heading {
            color: $progress-bar-bg;
        }

        .step-subheading {
            color: $gray-light;
        }
    }

    .step.active ~ .step {
        border-color: $progress-bg;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 40px;

        .step-heading,
        .step-subheading {
            display: block;
        }
    }
}

@import "variables", "mixins";

.floating-list {
    padding: 0;
    list-style: none;

    li {
        display: inline-block;
        margin-bottom: 10px;
        margin-right: 20px;
        padding: 0;
    }

    .btn-inline {
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
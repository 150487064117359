@import "variables", "mixins";

//
// Textual form controls
//

.form-control {
    font-size: 1rem;

    // Read-only inputs
    &[readonly] {
        background-color: $input-bg;
    }

    &:first-child[readonly] {
        background-color: $input-bg-disabled;
        border-color: $input-border-color;
    }

    &::placeholder {
        font-style: italic;
    }

    @include media-breakpoint-up(md) {
        font-size: $font-size-base;
    }
}


//
// Form Label
//

.form-group {
    position: relative;
}

.form-control ~ label {
    position: absolute;
    top: -.5625rem;
    left: $input-padding-x;
    margin-left: -4px;
    margin-bottom: 0;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: content-box;
    max-width: calc(100% - #{$input-padding-x * 2});
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    line-height: $line-height-sm;
    color: $input-color-placeholder;
    background-color: #fff;
    border-radius: $border-radius;
    transition: opacity .2s, transform .2s ease-out;
}

.form-control:placeholder-shown + label {
    opacity: 0;
    transform: translateY(5px);
}

.form-control:-ms-input-placeholder + label {
    opacity: 0;
    transform: translateY(5px);
}


// Form Grid
//
// Create horizontal layout with evenly spaced form fields

.form-grid {
    display: flex;
    margin-left: -.25rem;
    margin-right: -.25rem;

    > * {
        flex-grow: 1;
        padding-left: .25rem;
        padding-right: .25rem;
    }
}

.form-grid-center {
	text-align: center;
}


// Form Buttons Group

.form-buttons-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn + .btn {
        margin-top: .5rem;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .btn + .btn {
            margin-top: 0;
        }
    }
}


// Form Text

.form-control-feedback {
    margin-top: 0;
    margin-bottom: 0;
}

.form-control-help {
    clear: left;
    margin-top: 0;
    margin-bottom: 0;
    font-size: $font-size-sm;
    color: rgba($gray-light, .5);
}

.form-control + .form-control-feedback,
.form-control + .form-control-help {
    margin-top: $form-text-margin-top;
}


// Select boxes

select.form-control {
    appearance: none;
    background-image: $custom-select-indicator;
    background-size: $custom-select-bg-size;
    background-position: right $custom-select-padding-x center;
    background-repeat: no-repeat;

    .has-success &,
    .has-error & {
        background-image: $custom-select-indicator;
    }
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio {
    input[type="radio"] {
        top: 0;
        left: 0;
        width: 2.5rem;
        height: 100%;
        margin: 0;
        cursor: pointer;
        opacity: 0;
        z-index: 1;
    }

    label {
        padding-left: 2.5rem;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 0;
            height: 25px;
            width: 25px;

            border-radius: 50%;
            transform: translateY(-50%);
            content: '';
        }

        &:before {
            border: solid 1px #ccc;
        }

        &:after {
            border: solid 4.5px transparent;
            background-color: #ccc;
            background-clip: content-box;

            transition: background-color 0.2s;
        }

        input:only-child {
            position: absolute;
        }
    }

    input[type="radio"]:checked + label {
        &:after {
            background-color: $brand-primary;
        }
    }
}

.checkbox {
    input[type="checkbox"] {
        top: 0;
        left: 0;
        width: 4.5rem;
        height: 100%;
        margin: 0;
        cursor: pointer;
        opacity: 0;
        z-index: 1;
    }

    label {
        padding-left: 4.5rem;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            left: 0;
            height: 25px;

            border-radius: 12.5px;
            content: '';
        }

        &:before {
            width: 50px;
            background-color: #ccc;
            transform: translateY(-50%);
            transition: background-color 0.2s;
        }

        &:after {
            width: 25px;
            border: solid 1px transparent;
            background-color: #fff;
            background-clip: content-box;
            transform: translateY(-50%) translateX(0%);
            transition: transform 0.2s ease-out;
        }
    }

    input[type="checkbox"]:checked + label {
        &:before {
            background-color: $brand-primary;
        }

        &:after {
            transform: translateY(-50%) translateX(100%);
        }
    }
}
@import "variables", "mixins";

//
// Base styles
//

.card {

}


//
// Hoverable Card
//

.card-hoverable {
    cursor: pointer;
}


//
// Pricing Card
//

.card-pricing {
    margin-bottom: 30px;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    border: solid 1px $brand-primary;
    border-radius: $border-radius-sm;

    .card-block {
        padding: 1rem;
    }

    .card-footer {
        padding: 0 1rem;
        background-color: transparent;
        border-top: none;
    }

    .card-title {
        margin-bottom: 0.625rem; // 10px
        color: $blue-dark;
        font-size: 1.25rem; // 20px
        font-weight: $font-weight-semibold;
    }

    .card-subtitle {
        margin-bottom: 0.625rem;
        color: fade-out($gray-dark, .4);
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
    }

    .price {
        margin-bottom: 1.5rem;
        color: $brand-primary;
        font-size: $font-size-h1;
        letter-spacing: -0.04em;

        small {
            display: block;
            color: #1f242b;
            letter-spacing: normal;
            opacity: 0.6;
        }
    }

    .card-text {
        font-size: $font-size-xs;
    }
}

.card-pricing.card-hoverable {
    box-shadow: 0 5px 20px rgba(0,0,0,0);
    transition: box-shadow .3s;

    &:hover {
        box-shadow: 0 5px 20px rgba(0,0,0,.1);
    }
}

.card-pricing-alt {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
    // width: 260px;
    border: none;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: solid ($border-radius-sm + 0.1rem) $brand-primary;
        border-top-left-radius: $border-radius-sm;
        border-top-right-radius: $border-radius-sm;
        overflow: hidden;
        content: '';
    }

    .card-title {
        margin-bottom: .5rem;
    }

    .card-subtitle {
        margin-bottom: 1.25rem;
        font-weight: $font-weight-normal;
    }

    .price {
        margin-bottom: 0;
        font-size: 3.75rem;
        line-height: 1.5;
    }

    .card-text {
        margin-bottom: 1.5rem;

        strong {
            display: block;
            margin-bottom: 0.5rem;
            font-size: $font-size-sm;
        }
    }
}


//
// Accordian Card
//

.card-accordian {
    border: none;
    border-top: solid 1px $hr-border-color;
    border-radius: 0;

    &:last-child {
        border-bottom: solid 1px $hr-border-color;
    }

    .card-header {
        padding-top: 25px;
        padding-bottom: 25px;
        background-color: transparent;
        border-bottom: none;
    }

    .card-title {
        margin-bottom: 0;

        a {
            color: $body-color;
        }
    }

    .card-block:first-child {
        padding-top: 0;
    }

    .card-block > :last-child {
        margin-bottom: 0;
    }
}
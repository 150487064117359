@import "variables";

.help-toggle {
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    opacity: .7;
    transition: opacity .2s;

    &:hover {
        opacity: 1;
    }
}

.help-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    background-color: #fff;
    box-shadow: -4px 0 40px rgba(#000, .1);
    overflow-y: auto;
    transform: translateX(100%);
    opacity: 0;
    z-index: $zindex-fixed;
    transition: transform .3s ease-out, opacity .2s;

    &.show {
        transform: translateX(0%);
        opacity: 1;
    }

    h1 {
        margin-top: 24px;
        margin-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: 1.5rem;
    }

    iframe {
        margin-bottom: 16px;
        width: 100%;
        height: 168px;
    }

    p {
        margin-bottom: 24px;
        padding-left: 16px;
        padding-right: 16px;
        font-size: .875rem;
    }

    .help-close {
        display: block;
        margin: 12px;
        margin-left: auto;
        padding: 0;
        background: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;
        opacity: .5;
        transition: opacity .2s;
    
        &:hover {
            opacity: 1;
        }
    }
}
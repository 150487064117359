@import "variables", "mixins";

.modal {
    -webkit-overflow-scrolling: touch;
}

.modal-dialog {
    .modal.fade & {
        transform: translateY(20px);
    }

    .modal.show & {
        transform: translateY(0px);
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        align-items: center;
        padding: 2.5rem 0;
        min-height: 100%;
    }
}

.modal-content {
    min-height: 100vh;
    max-width: 100%;
    padding: $modal-content-padding;
    border-radius: 0;
    text-align: center;
    overflow-x: auto;

    @include media-breakpoint-up(sm) {
        flex-grow: 1;
        min-height: 0;
        padding: $modal-content-padding-md;
        box-shadow: $modal-content-box-shadow;
        border-radius: $border-radius;
    }
}

.modal-header {
    display: block;
    margin-bottom: $spacer * 2;
}

.modal-body {
    margin-bottom: $spacer * 2;
    text-align: left;

    .modal-buttons {
        text-align: center;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.modal-footer {
    display: block;

    // > :not(:last-child) {
    //     margin-bottom: .25rem;
    // }
}

.modal-backdrop {
    background-color: transparent;

    @include media-breakpoint-up(sm) {
        background-color: $modal-backdrop-bg;
    }
}

// include additional modal sizes
@include media-breakpoint-up(xl) {
    .modal-xl { max-width: $modal-xl; }
    .modal-xxl { max-width: $modal-xxl; }
}
@import "variables", "mixins";

.block {
    padding: $block-spacing-xs 0;

    @include media-breakpoint-up(sm) {
        padding: $block-spacing-sm 0;
    }

    @include media-breakpoint-up(md) {
        padding: $block-spacing-md 0;
    }
}

.block-min-gap {
    padding: $block-small 0;
}

.block-primary {
    background-color: fade-out($brand-primary, .1);
    color: #fff;

    + .block-primary {
        padding-top: 0;
    }
}

.block-secondary {
    background-color: fade-out($brand-secondary, .1);
    color: #fff;

    + .block-secondary {
        padding-top: 0;
    }
}

.block-dark {
    background-color: $blue-dark;
    color: #fff;

    + .block-dark {
        padding-top: 0;
    }
}

.block-light {
    background-color: #f6f7fb;

    + .block-light {
        padding-top: 0;
    }
}

.block-default {
    + .block-default {
        padding-top: 0;
    }
}
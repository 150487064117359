@import "variables", "mixins";

.comp-ajax-spinner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(#fff, .5);
    z-index: $zindex-spinner;

    &.active {
        display: flex;
    }

    @supports (pointer-events: none) {
        display: flex;
        pointer-events: none;
        opacity: 0;
        transition: opacity .2s;

        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

    i {
        width: 30px;
        height: 29px;
        font-size: 30px;
        animation: rotate .7s linear infinite;
    }
}

// header ~ .comp-ajax-spinner {
//     top: $navbar-height-xs;

//     @include media-breakpoint-up(sm) {
//         top: $navbar-height-sm;
//     }
// }

@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
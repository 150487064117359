@import "variables", "mixins";

.comp-apps {
    h2 {
        margin-bottom: 2rem;
        line-height: 1.11;
    }

    p {
        margin-bottom: 0;
    }

    .btn-link {
        display: block;
        margin-bottom: 1rem;
        font-size: $font-size-sm;
        text-align: center;
        color: inherit;

        &:hover {
            color: $brand-primary;
        }

        i {
            display: block;
            margin-bottom: .25rem;
            color: $brand-primary;
            font-size: 2rem;
        }
    }

    @include media-breakpoint-up(sm) {
        .btn-link {
            margin-bottom: 1.25rem;
            text-align: left;

            i {
                display: inline;
                margin-right: 1rem;
                vertical-align: middle;
            }
        }
    }
    
    @include media-breakpoint-up(xl) {
        h2 {
            font-size: 2.25rem;
        }
    }
}
@import "variables", "mixins";

.go-back.title {

	display: inline-block;
	width: 1.5rem;
	margin-right: .875rem;
	
	line-height: 0;
	stroke: $blue;
	fill: $blue;
}


/* When positioning a page using "#anchorname" give this an offset from the top of the screen */
.anchor {
	display: block;
	position: relative;
	top: -150px;
	visibility: hidden;	
}


.go-back.calendar {
	background-color: inherit;
	border-radius: .25rem;
	height: 1.6rem;
	width: 1.75rem;
	stroke: $blue;
	fill: $blue;
	padding-top: 2px;
}

.go-forward.calendar {
	background-color: inherit;
	border-radius: .25rem;
	height: 1.6rem;
	width: 1.75rem;
	stroke: $blue;
	fill: $blue;
	padding-top: 2px;
}

.go-back {
	@media print {
		display: none !important;
	}
}
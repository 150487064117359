@import "variables", "mixins";

//
// Base styles
//

.btn {
    min-width: 150px;
    max-width: 100%;
    font-size: 1rem;
    border-width: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    margin: 0;
    padding: 0;
    min-width: 0;
    border: none;
    font-size: inherit;
    font-weight: inherit;

    &:focus {
        box-shadow: none;
    }

    @include hover {
        text-decoration: none;
    }
}


//
// Icon buttons
//

.btn-icon {
    padding: 0;
    width: 1rem; // 1.25rem;
    height: 1rem; // 1.25rem;
    min-width: 0;
    border-radius: 50%;
    line-height: 1rem;
    box-sizing: content-box;

    &::before {
        border-radius: 20%;
    }

    > i {
        font-size: .5rem;
        vertical-align: middle;
    }
}

.input-group-btn > .btn-icon {
    padding: 0 .5rem;
    width: auto;
    height: auto;
    border-radius: $btn-border-radius;
    line-height: $btn-line-height;

    &::before {
        border-radius: 0;
    }

    > i {
        font-size: .625rem;
    }
}


//
// Alternate buttons
//

.btn-default {
    @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-outline-default {
    @include button-outline-variant($btn-default-bg);
}

.btn-unavailable {
    @include button-variant($gray, $white, $gray-light);
    &:hover {
        background-color: $gray;
        color: $white;
    }
}


.btn-inline {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    padding: .125rem .5rem;
    margin: .5rem;
    min-width: 0;
    border-radius: .25rem;
    // border: none;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
}


//
// Dark buttons
//

.btn-dark {
    &.btn-default {
        @include button-dark-variant($btn-default-color, $btn-default-bg, $btn-default-border);
    }

    &.btn-primary {
        @include button-dark-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
    }

    &.btn-secondary {
        @include button-dark-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
    }

    &.btn-outline-default {
        @include button-dark-outline-variant($btn-default-color, $btn-default-bg);
    }

    &.btn-outline-primary {
        @include button-dark-outline-variant($btn-primary-color, $btn-primary-bg);
    }

    &.btn-outline-secondary {
        @include button-dark-outline-variant($btn-secondary-color, $btn-secondary-bg);
    }

    &.btn-link {
        color: #fff;

        @include hover {
            color: $link-hover-color;
        }
    }
}


//
// Button Sizes
//

.btn-xl {
    @include button-size($btn-padding-y-xl, $btn-padding-x-xl, 1.25rem, $btn-border-radius-lg);
    line-height: $line-height-lg;
}

.btn-lg {
    font-size: $font-size-base;
}

.btn-sm {
    min-width: 0;
    font-size: $font-size-sm;
}

.btn-xs {
    @include button-size($btn-padding-y-xs, $btn-padding-x-xs, .8rem, $btn-border-radius-sm);
    min-width: 0;
    font-size: $font-size-xxs;
}
// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-dark-variant($color, $background, $border) {
    $dark-color: $background;
    $dark-background: $color;
    $dark-border: $color;

    @include button-variant($dark-color, $dark-background, $dark-border);
}

@mixin button-outline-variant($color, $color-hover: #fff) {
    position: relative;
    color: $color;
    background-image: none;
    background-color: transparent;
    border-color: $color;
    z-index: 0;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color;
        transform-origin: center;
        transform: scaleX(.8);
        opacity: 0;
        transition: opacity, transform cubic-bezier(0.165, 0.84, 0.44, 1);
        transition-duration: inherit;
        z-index: -1;
        content: '';
    }

    @include hover {
        color: $color-hover;
        border-color: $color;

        &:before {
            opacity: 1;
            transform: scaleX(1);
        }
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 2px rgba($color, .5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;

        &:before {
            opacity: 0;
            transform: scaleX(.8);
        }
    }

    &:active,
    &.active,
    .show > &.dropdown-toggle {
        color: $color-hover;
        border-color: $color;

        &:before {
            opacity: 1;
            transform: scaleX(1);
        }
    }
}

@mixin button-dark-outline-variant($color, $background) {
    $dark-color: $background;
    $dark-background: $color;

    @include button-outline-variant($dark-background, $dark-color);
}

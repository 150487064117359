@import "variables", "mixins";

.panel {
    padding: 0 20px;
    border-top: solid 1px $hr-border-color;

    &:last-child {
        border-bottom: solid 1px $hr-border-color;
    }
}

.panel-title {
    margin-bottom: 0;
    padding: 25px 0;

    a {
        color: $body-color;
    }
}

.panel-collapse {
    &:after {
        display: block;
        height: 25px;
        content: '';
    }

    > p:last-child {
        margin-bottom: 0;
    }
}
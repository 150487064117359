@import "variables", "mixins";

// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
    display: flex;
}


// Custom indicator
//
// Generates a shadow element to create our makeshift toggle/radio background.

.custom-control-indicator {
    // top: 50%;
    top: .5rem;
    margin-top: -$custom-control-indicator-size / 2;
    opacity: 1;
    transition: background-color .2s, border-color .2s, box-shadow .2s, opacity .2s;

    &:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        background-clip: content-box;
        transition: background-color .2s, border-color .2s;
        content: '';
    }
}

.custom-control-input:active ~ .custom-control-indicator {
    background-color: $custom-control-indicator-bg;
    opacity: .5;
}


// Toggles
//
// Tweak just a few things for toggles.

.custom-toggle {
    padding-left: $custom-toggle-gutter;

    .custom-control-indicator {
        width: $custom-toggle-indicator-size * 2;
        height: $custom-toggle-indicator-size;
        margin-top: -$custom-toggle-indicator-size / 2;
        background-color: $custom-toggle-bg;
        border-radius: $custom-toggle-radius;
        transition: opacity .2s, background-color .3s, box-shadow .2s;

        &:after {
            width: $custom-toggle-indicator-size;
            border: solid 1px $custom-toggle-indicator-border;
            background: $custom-toggle-indicator-bg;
            transform: translateX(0);
            transition: transform .3s ease-out, border-color .3s;
        }
    }

    .custom-control-input:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 2px rgba($custom-toggle-bg, .5);
    }

    .custom-control-input:checked ~ .custom-control-indicator {
        background-color: $custom-toggle-checked-bg;

        &:after {
            border-color: $custom-toggle-checked-indicator-border;
            transform: translateX(100%);
        }
    }

    .custom-control-input:checked:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 2px rgba($custom-toggle-checked-bg, .5);
    }
}


// Checkboxes
//
// Tweak just a few things for checkboxes.

.custom-checkbox {
    margin-bottom: 0;

    .custom-control-indicator {
        border: solid 2px $custom-checkbox-border;
        background-color: $custom-checkbox-bg;

        &:after {
            display: none;
        }
    }

    .custom-control-input:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 2px rgba($custom-checkbox-border, .5);
    }

    .custom-control-input:active ~ .custom-control-indicator {
        background-color: $custom-checkbox-bg;
    }

    .custom-control-input:checked ~ .custom-control-indicator {
        border-color: $custom-checkbox-checked-border;
        background-color: $custom-checkbox-checked-bg;
    }

    .custom-control-input:checked:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 2px rgba($custom-checkbox-checked-border, .5);
    }
}


// Radios
//
// Tweak just a few things for radios.

.custom-radio {
    .custom-control-indicator {
        border: solid 2px $custom-radio-border;
        background-color: $custom-radio-bg;

        &:after {
            top: -2px;
            left: -2px;
            border: solid 4px $custom-radio-indicator-border;
            background-color: $custom-radio-indicator-bg;
        }
    }

    .custom-control-input:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 2px rgba($custom-radio-border, .5);
    }

    .custom-control-input:active ~ .custom-control-indicator {
        background-color: $custom-radio-bg;
    }

    .custom-control-input:checked ~ .custom-control-indicator {
        border-color: $custom-radio-checked-border;
        background-color: $custom-radio-bg;

        &:after {
            border-color: $custom-radio-indicator-border;
            background-color: $custom-radio-checked-indicator-bg;
        }
    }

    .custom-control-input:checked:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 2px rgba($custom-radio-checked-border, .5);
    }

    &.custom-control-inline {
        display: inline-block;
        margin-left: 2rem;
    }
}


// Badges
//
// A checkbox variant for selectable items

.custom-badge {
    display: inline-block;
    padding-left: 2px;

    .custom-control-description {
        display: inline-block;
        padding: $custom-badge-padding;
        background-color: $custom-control-indicator-bg;
        border-radius: $custom-badge-radius;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
        color: $gray;
        transition: opacity .2s, box-shadow .2s, background-color .2s, color .2s;
    }

    .custom-control-input:focus ~ .custom-control-description {
        box-shadow: 0 0 0 2px rgba($custom-control-indicator-bg, .5);
    }

    .custom-control-input:active ~ .custom-control-description {
        opacity: .5;
    }

    .custom-control-input:checked ~ .custom-control-description {
        background-color: $custom-control-checked-indicator-bg;
        color: $custom-control-checked-indicator-color;
    }

    .custom-control-input:checked:focus ~ .custom-control-description {
        box-shadow: 0 0 0 2px rgba($custom-control-checked-indicator-bg, .5);
    }
}
@import "variables", "mixins";

.comp-get-started {
    h2 {
        margin-bottom: .25rem;
        font-size: 2.25rem;
        line-height: 1;
    }

    .lead {
        margin-bottom: 2rem;
        font-size: $font-size-lg;
        line-height: 1.25;
    }

    @include media-breakpoint-up(xl) {
        .lead {
            margin-bottom: 0;
        }
    }
}
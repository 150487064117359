@import "variables", "mixins";

// The dropdown menu
.dropdown-menu {
    z-index: 999;

    &::before {
        position: absolute;
        left: .375rem;
        bottom: 100%;
        display: block;
        width: 0;
        height: 0;
        border-left: solid .375rem transparent;
        border-right: solid .375rem transparent;
        border-bottom: solid .375rem $dropdown-bg;
        content: '';
    }


    div.dropdown-comments { 
        margin-left: 4px;
        margin-right: 4px;
        margin-bottom: 8px;
        border-radius: 3px;
        background-color: white;
    }

    @supports (pointer-events: none) {
        display: block;
        opacity: 0;
        transform: translateY(-4px);
        pointer-events: none;
        transition: opacity .2s, transform .2s ease-out;

        .show > & {
            opacity: 1;
            transform: translateY(0px);
            pointer-events: all;
        }
    }
}

.dropdown-menu-right {
    &::before {
        left: auto;
        right: .375rem;
    }
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    font-size: $font-size-xs;
    cursor: pointer;
}

.dropdown-comment {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    font-size: $font-size-xs;
    cursor: default;
    color: $blue;
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
    cursor: default;
}